import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;

  .errorCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ant-primary-1);
    height: 150px;
    margin-top: 35px;
    border-radius: 16px;
    border: 1px solid var(--ant-primary-color);
    padding-left: 30px;

    .errorTitle {
      color: #000;
      font-weight: 600;
      font-size: 18px;
    }

    .errorText {
      color: #000;
    }
  }

  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  h2.ant-typography {
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 700px;
    margin: 0 auto 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);

    &.info {
      align-items: flex-start;
      font-size: 0.875rem;
      line-height: 1.125rem;
      opacity: 0.85;
      margin: 0 auto 2.5rem;
      svg {
        margin-top: 4px;
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;

    h1 {
      width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    h2.ant-typography {
      width: 100%;
      margin: 0 auto 1.5rem;
      padding: 0 24px;
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  margin: 0 auto;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);
  h1 {
    margin: 0 0 8px;
    font-size: 20px;
    color: var(--velotax-font-color);
  }
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--velotax-font-color);
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px 24px;
    border-radius: 0;
    width: 100%;
    .ant-list.ant-list-split {
      .ant-list-item-action {
        display: grid;
        align-items: end;
        grid-template-rows: 1fr 0.75fr;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .monthError {
    text-align: center;
    .warning {
      transform: translateY(2px);
      color: var(--ant-warning-color);
      margin-right: 6px;
      font-size: 16px;
    }
  }
`;


export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`